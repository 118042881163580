import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Album from "../components/Album"
import Footer from "../components/Footer";
import Address from "../components/Address";
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  wa: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  waIcon: {
    marginRight: theme.spacing(2)
  },
  heroButtons: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  }
}));

const Bantul = () => {
  const classes = useStyles();

  const onClick = () => {
    window.open('https://wa.me/62818870072', '_blank').focus();
  };

  return (
  <Layout>
    <Seo 
    title="Jasa Derek Bantul | Bobby Towing" 
    description="Jasa derek mobil, motor, dan alat berat Bobby Towing Bantul. Buka 24 Jam dan pakai asuransi. Melayani pengiriman mobil, motor, harley, dan alat berat lainnya." 
    keyword="jasa derek Bantul, jasa derek mobil Bantul, jasa derek motor Bantul" />
    <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Jasa Derek Mobil, Motor, dan Alat Berat<br /> 
              Bobby Towing Bantul
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Melayani pengiriman mobil, motor, dan alat berat lainnya. <br />
              Jangan khawatir, harga bersahabat!
            </Typography>
            <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
              <strong>BUKA 24 JAM</strong><br />
              Pakai <strong>asuransi</strong>
            </Typography>
            <Typography className={classes.wa} variant="h4" align="center" color="textPrimary" gutterBottom>
              <WhatsAppIcon className={classes.waIcon} fontSize="large" /><span>0818870072</span>
            </Typography>
            <div className={classes.heroButtons}>
              <Button variant="contained" color="primary" size="large" onClick={onClick}>
                Pesan Sekarang
              </Button>
            </div>
          </Container>
        </div>
        <Album length={12} />
        <Address />
    </main>
    <Footer />
  </Layout>
  )
}

export default Bantul;
